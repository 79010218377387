<template>
    <v-container>
        <v-row align-content="center" justify="center">
            <v-col cols="12" class="text-center">
                <slot>Loading data</slot>
            </v-col>
            <v-col cols="6">
                <v-progress-linear indeterminate rounded height="6"></v-progress-linear>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'DashboardLoader'
}
</script>
